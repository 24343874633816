<template>
  <div>
    <h2>Лимиты</h2>
    <el-row :gutter="10">
        <el-col :span="4">{{ " " }}</el-col>
        <el-col :span="8">
          <el-select
            clearable
            class="searchPole"
            v-model="search.type"
            filterable
            remote
            reserve-keyword
            placeholder="Тип лимита"
          >
            <el-option
              v-for="item in limitstype"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select
            clearable
            class="searchPole"
            v-model="search.waste"
            filterable
            remote
            reserve-keyword
            placeholder="Отход"
          >
            <el-option
              v-for="item in wastes"
              :key="item.organizationWasteId"
              :label="item.organizationInfo.name +'-'+item.ss01WasteDictionaryDTO.wasteCode+ '/'+item.ss01WasteDictionaryDTO.wasteName"
              :value="item.organizationWasteId"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
            <el-button @click="reload()">
                Обновить
            </el-button>
        </el-col>
    </el-row>
    <el-row :gutter="2">
        <el-col :span="2">ID</el-col>
        <el-col :span="4">Дата начала действия</el-col>
        <el-col :span="4">Дата конца действия</el-col>
        <el-col :span="5">{{search.type!=4 ? "Объем" : "Размерность"}}</el-col>
        <el-col :span="5">Объекты</el-col>
        <el-col :span="4"></el-col>
    </el-row>
    <el-row v-for="(l,index) in limits" :key="index">
        <el-col :span="2" style="height: 40px;">{{ l.id != null ? l.id : " " }}</el-col>
        <el-col :span="4">
            <el-date-picker
            style="width: 100%"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="Дата начала"
            v-model="l.date_from"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="4">
            <el-date-picker
            style="width: 100%"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="Дата окончания"
            v-model="l.date_to"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="5" >
            <el-input v-if="search.type!=4"
                placeholder="Объем"
                v-model="l.k"
              ></el-input>
              <el-input v-if="search.type==4"
                placeholder="Размерность"
                v-model="l.dimension"
              ></el-input>
        </el-col>
        <el-col :span="5">
            <el-tree
                :data="l.orgsList"
                show-checkbox
                node-key="id"
                :ref="'tree'+index"
                :props="defaultProps"
                style="font-weight: bold;"
                >
            </el-tree>
        </el-col>
        <el-col :span="4">
            <el-button @click="save(l,index)" :disabled="prod==1" >
                Сохранить
            </el-button>
            <el-button @click="delet(l,index)" :disabled="prod==1" >
                Удалить
            </el-button>
        </el-col>
    </el-row>
    <el-row>
        <el-col :span="10">{{ " " }}</el-col>
        <el-col :span="4"> 
            <el-button @click="create()" :disabled="prod==1" >
                Создать
            </el-button>
        </el-col>
        <el-col :span="10">{{ " " }}</el-col>
    </el-row>
        

  </div>
</template>
<script>

import { AXIOS } from "@/AXIOS/http-common";
import Cookies from "js-cookie";
export default {
  name: "limitsMain",
  data() {
    return {
      prod:0,
        limits:[],
        orgsList:[],
        wastes:[{id:null,organizationInfo:{name:"Объект к которому прявязан отход"},ss01WasteDictionaryDTO:{wasteName:"Отход",code:"Код отхода"}}],
        search:{type:null,},
        limitstype: [
            {id:1,name:"Транспортная единица"},
            {id:2,name:"Разрешение на захоронение"},
            {id:3,name:"Разрешение на хранение"},
            {id:4,name:"Норматив образования отхода"}
        ],
        defaultProps: {
        children: "children",
        label: "name",
        },
    };
  },
  methods: {
    async reload()
    {
        await AXIOS.get(
            "user/organization/hierarchyOrgByWaste?waste=" + this.search.waste
        ).then(async (response) => {
            this.orgsList=response.data;
            await AXIOS.post("Limits/get",{wasteID:this.search.waste,type:this.search.type}).then( (response) => {
                response.data.forEach((el)=>{
                    el.date_from=el.date_from!=null?new Date(el.date_from):null;
                    el.date_to=el.date_to!=null?new Date(el.date_to):null;
                });
                this.limits=response.data;
                this.limits.forEach( (limit,index)=>{
                    limit.orgsList= this.orgsl();
                    this.$nextTick(() => {
                        let orgChekId=[];
                        limit.limitsOrg.forEach(limitsOr => {
                            orgChekId.push(limitsOr.organization_id);
                        });
                        console.log(orgChekId);
                        this.$refs["tree"+index][0].setCheckedKeys(orgChekId);
                });
                });
            });
        });
    },
    async save(limit,index)
    {
        let lim={
            id:limit.id,
            date_from:limit.date_from,
            date_to:limit.date_to,
            k:parseFloat((limit.k+'').replace(',','.')),
            type:this.search.type,
            organization_waste_id:this.search.waste,
            dimension:limit.dimension,
            limitsOrg:[]
        };
        this.$refs["tree"+index][0].getCheckedNodes().forEach(element => {
            lim.limitsOrg.push({organization_id:element.id,limits_id:limit.id});
        });
        if(limit.id!=null)
          await AXIOS.post("Limits/update",lim);
        else
          await AXIOS.post("Limits/create",lim);
        this.reload();
    },
    async delet(limit,index)
    {
      let lim={
            id:limit.id,
            date_from:limit.date_from,
            date_to:limit.date_to,
            k:parseFloat((limit.k+'').replace(',','.')),
            type:this.search.type,
            organization_waste_id:this.search.waste,
            dimension:limit.dimension,
            limitsOrg:[]
        };
        this.$refs["tree"+index][0].getCheckedNodes().forEach(element => {
            lim.limitsOrg.push({organization_id:element.id,limits_id:limit.id});
        });
        if(limit.id!=null)
          await AXIOS.post("Limits/delete",lim);
        this.reload();
    },

    orgsl()
    {
        let orgsl=[{id: this.orgsList[0].id,name: this.orgsList[0].name,children: [],}];
        this.orgsList.forEach(element => {
            if(element.parent == orgsl[0].id)
                orgsl[0].children.push({id:element.id,name:element.name,children: []});
        });
        orgsl[0].children.forEach(child => {
            this.orgsList.forEach(element => {
                if(element.parent == child.id)
                child.children.push({id:element.id,name:element.name,children: []});
            });
            child.children.forEach(child2 => {
                this.orgsList.forEach(element => {
                    if(element.parent == child2.id)
                    child2.children.push({id:element.id,name:element.name,children: []});
                });
            });
        });
        return orgsl;
    },
    create()
    {
        this.limits.push({id:null,orgsList:this.orgsl()});
    }
  },
  async mounted() {
    console.log("Cookies.get('prod'):"+Cookies.get('prod'));
    console.log("Cookies:"+Cookies);
    this.prod = Cookies.get('prod');
    await AXIOS.get("organization-waste/list?page=" + 0 + "&size="+10000).then(
      (response) => {
        console.log("response.data");
        console.log(response.data.content);
        this.wastes=response.data.content.sort((a, b) => {
  const nameA = a.organizationInfo.name.toUpperCase()+a.ss01WasteDictionaryDTO.wasteName.toUpperCase(); // ignore upper and lowercase
  const nameB = b.organizationInfo.name.toUpperCase()+b.ss01WasteDictionaryDTO.wasteName.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
        });
      }
    );
    
  },
}
</script>